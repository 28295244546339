import React, { useState, useEffect } from 'react';
import { observer, inject } from "mobx-react";
import { useTranslation } from "react-i18next";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    InfoWindow
    
} from "react-google-maps";
import Modal from 'react-modal';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { IoEllipse } from 'react-icons/io5';
import markerUser from 'assets/delivery/location_user.png';
import markerShop from 'assets/delivery/location_rest.png';
import markerCourier from 'assets/delivery/location_delivery.png';
import style from './style.module.css';

const MapWithAMarker = withScriptjs(withGoogleMap( props => {
    const { order, getOrderDelivery, setEta } = props;

    const [ orderDelivery, setOrderDelivery ] = useState(
        {
            "deliveryLat": "32.060647",
            "deliveryLon": "34.7741449",
            "shop": {
                "shopLat": props.shop.shopLat,
                "shopLon": props.shop.shopLon
            },
            "delivery": null
        }
    );

    const icons = new Map([
        ['yango', 'assets/delivery/CourierYango.png'],
        ['wolt', 'assets/delivery/CourierWolt.png'],
    ]);
    const { t } = useTranslation();
    const [ info, setInfo ] = useState({
        marker: {},
        visible: false,
        selectedPlace: {}
    });
    useEffect(() => {
        const prevLocation ={ lat: 0, lon: 0};
        const loopManager = { tries: 10, etaUpdate: true};

        const fetchPosition = () => {
            order?.id && getOrderDelivery(order.id, loopManager.etaUpdate).then(resp => {
                if(resp?.order?.delivery) {
                    const { carrierLat, carrierLon } = resp.order.delivery;
                    loopManager.etaUpdate = false;
                    if (carrierLat && carrierLon && loopManager.tries>0) {
                        setOrderDelivery(resp.order)
                        prevLocation.lat === carrierLat &&
                        prevLocation.lon === carrierLon && loopManager.tries--;
                        prevLocation.lat = carrierLat;
                        prevLocation.lon = carrierLon;
                    } else {
                        loopManager.tries = 10;
                        !carrierLat && setOrderDelivery({
                            ...resp.order,
                            ...{delivery: {...getDefaultPosition(resp.order), codename: resp.order.delivery.codename}}
                        })
                    }

                    setEta(resp.order.delivery?.ETA ?? 0);
                }
            })
        }

        fetchPosition();
        const intervalId = setInterval(() => {
            loopManager.tries > 0 ? fetchPosition() : clearInterval(intervalId);
        }, 15000);

        const etaUpdateInterval = setInterval(() => {
            loopManager.etaUpdate = true;
        }, 45000);

        return () => { clearInterval(intervalId); clearInterval(etaUpdateInterval); };
    }, [order, getOrderDelivery, setEta]); // הוספתי את setEta לתלות

    const getDefaultPosition = (order) => {

        const toRadians = (degrees) => degrees * (Math.PI/180);
        const toDegrees = (radians) => radians * (180/Math.PI);

        const latm = (toRadians(order.deliveryLat) + toRadians(order.shop.shopLat))/2;
        const lonm = (toRadians(order.deliveryLon) + toRadians(order.shop.shopLon))/2;
        return { 
            carrierLat: toDegrees(latm),
            carrierLon: toDegrees(lonm),
        };
    }
    const onMarkerClik = (props, marker, e) => { setInfo({selectedPlace: props, marker: marker, visible: true});}
    const onMapClicked = (props) => { if (info.visible) setInfo({selectedPlace: {}, marker: {}, visible: false}) }
    return (
        <GoogleMap
            defaultZoom={14}
            defaultCenter={
                !orderDelivery.delivery?
                    { lat: props.shop.shopLat, lng: props.shop.shopLon }: 
                    { lat: parseFloat(orderDelivery.delivery.carrierLat), lng: parseFloat(orderDelivery.delivery.carrierLon) }
            }
            onClick={onMapClicked}
        >
            <Marker
                name={'Your Location'}
                onClick={onMarkerClik}
                position={{ lat: parseFloat(orderDelivery.deliveryLat), lng: parseFloat(orderDelivery.deliveryLon) }}
                icon={{ url: markerUser, scaledSize: { width: 70, height: 70 } }}
            />
            {!!orderDelivery.delivery && <Marker 
                name={'courier location'}
                onClick={onMarkerClik}
                position={{ lat: parseFloat(orderDelivery.delivery.carrierLat), lng: parseFloat(orderDelivery.delivery.carrierLon) }}
                icon={{
                    url: markerCourier, 
                    scaledSize: { width: 70, height: 70 },
                }}
                // {...(icons.has(orderDelivery.delivery.codename) ? 
                //     {icon:{
                //         url: icons.get(orderDelivery.delivery.codename),
                //         scaledSize: { width: 70, height: 70 },
                //     }} : {})
                // }
                >
                    {info.visible && <InfoWindow marker={info.marker} visible={info.visible} > 
                        <div><h1>{t('courier location')}</h1></div>
                    </InfoWindow>}
                </Marker>
            }
            <Marker 
                name={props.shop.shopName}
                onClick={onMarkerClik}
                position={{ lat: orderDelivery.shop.shopLat, lng: orderDelivery.shop.shopLon }}
                icon={{ url: markerShop, scaledSize: { width: 70, height: 70 }}}
            />
        </GoogleMap>
    )}
));

const CurierLocation = inject("myShopApiStore")(observer((props) => {
    const { shop, getOrderDelivery } = props.myShopApiStore;
    const brdRadius =  getComputedStyle(document.querySelector(':root')).getPropertyValue('--brdRadius')
    return (
        <div style={{zIndex: 12, height: props.height ?? '300px'}}>
            <MapWithAMarker 
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                loadingElement={<div style={{ height: '100%' }} />}
                containerElement={<div style={{ height: '100%' }} />}
                mapElement={<div style={{ height: '100%', borderRadius: `0 0 ${brdRadius} ${brdRadius}` }} />}

                getOrderDelivery={getOrderDelivery}
                order={props.order}
                setEta={props.setEta}
                shop={shop}
            />
        </div>
    )

}));


const Milestone = ({ status, format, eta, activeStatus }) => {
    const { t } = useTranslation();
    return (
        <article className={`${style.Milestone} ${style[format]}`}>
            { format === 'Completed' 
                ? <IoIosCheckmarkCircle className={style.Checkbox} />
                : <IoEllipse className={style.Checkbox} />
            }
            <h4 style={{
                display: 'inline-block', 
                borderBottom: '1px solid black', 
                width: '85%', 
                margin: '0 0 10px 0'}}>
                    {status === 'picked_up' && eta ? t('Courier will be in about', {time: eta}) : t(status)}
            </h4>
        </article>
    )
}
const ProgressBar = (props) => {
    const { position, milestones, title, eta } = props;
    
    const getFormat = (s) =>{
        const shift = milestones.indexOf(s)-milestones.indexOf(position);
        return (
            {'-1': 'Completed', 0: 'Active',  1: 'Pending'}[
                !shift ? 0 : shift/Math.abs(shift)
            ] ?? 'Pending'
        )
    };
    
    return (
        <div className={style.ProgressBar}>
            <h1>{title}</h1>
            <section className={style.Milestones} >
                { milestones.map((s, i) => 
                    <Milestone key={i} status={s} eta={eta} activeStatus={position} format={getFormat(s)} />
                )}
            </section>
        </div>
    )
}

export const LocationModal = (props) => {
    const modalStyle = {
        content : {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            borderRadius: '20px',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            height: '90%',
            // maxHeight: '100%',
            // maxWidth: '100%',
            padding: '0',
            border: 'none',
            // backgroundColor: 'transparent',
            zIndex: '12'
        },
        overlay: {
            backgroundColor: "rgb(0, 0, 0, 0.65)",
            zIndex: 10
        }
    }

    const [ eta, setEta ] = useState(0);

    const { t } = useTranslation();

    useEffect(() => {
        props.order?.delivery?.trackingUrl && (modalStyle.content.height='100%')
    }, [props.order?.delivery?.trackingUrl, modalStyle.content]);

    const iphoneUrl = (url) => url.replace('https://', '//');
    return (
        <Modal style={modalStyle} isOpen={props.modalIsOpen} 
            onRequestClose={props.closeModal} 
        >
            <div className={style.ModalContainer}>
                    <div className={style.CancelXBtn}>
                        <span onClick={props.closeModal}>✕</span>
                    </div>
                    
                    <main className={style.Content} >
                        {!props.order?.delivery?.trackingUrl 
                            ? <>
                                <div className={style.CourierInfo} style={{ height:'120px'}}>
                                    <h1 style={{ marginBottom: '0' }}>{t('courier location')}</h1>
                                </div>
                                <CurierLocation order={props.order} setEta={setEta} 
                                    height='100%'
                                    //height='calc(100% - 120px)' 
                                    />
                                <ProgressBar
                                    title={t('order number') + ' ' + props.order?.id}
                                    milestones={['received', 'picked_up', 'delivered']}
                                    position={props.order?.delivery?.deliveryStatus?.codename ?? 'delivered'} 
                                    eta={eta}
                                    />
                            </>
                            : <iframe 
                                title={props.title ?? 'courier location'}
                                src={props.order.delivery.trackingUrl} 
                                style={{width: '100%', height: '100%'}}>
                              </iframe>
                        }
                    </main>
            </div>
        </Modal>
    )
}

export default CurierLocation;