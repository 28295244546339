import React, {useEffect, useRef, useState} from "react";
import NumberFormat from "react-number-format";
import { useTranslation } from "react-i18next";
import style from "./style.module.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import default_topping from 'assets/toppings/default_topping.png'
import pizza_tmp from 'assets/toppings/pizza_tmp.jpg'
import MaxToppingsMsg from "../MaxToppingsMsg";
//PIZZA
const SubForm3 = ({
  currencyName,
  handleChange,
  shopToppings,
  selectedToppings,
  selectedProductOption,
  toppingsDescription,
  toggleToppingOnQuarter,
  toggleToppingOnAllQuarters,
  removeTopping,
  optionsPaid,
  hasError,
  toppingsAddPaid,
  toppingsFree,
  hasProductOptions,
  maxToppings,
  closeMaxToppingMsg,
  withoutPrices,
  pizzaSlices,
  pizzaToppingPriceDivide
}) => {

  const [toppingToEdit, setToppingToEdit] = useState(null);
  const requestToppingImage = require.context('assets/toppings', true, /.png$/);
  const requestToppingPosImage = require.context('assets/topping_positions', true, /.png$/);
  const { t } = useTranslation();
  const scrollUpRef = useRef(null);

  const requireOr = (filename, fallback) => {
      let imgSrc = '';
      try {
        imgSrc = requestToppingImage(filename).default;
      }
      catch (error) {
        imgSrc = fallback;
        console.log(`image not found: ${filename}`);
      }
      return imgSrc;
  }

  useEffect(() => {
      scrollUpRef?.current?.scrollIntoView({behavior: 'smooth'})
  },[toppingToEdit])


  useEffect(() => {
    if(toppingToEdit){ //clear 'topping to edit' when user remove this topping.
        if(!selectedToppings.some(topping => topping.id == toppingToEdit.id)){
            setToppingToEdit(null);
        }
    }
    if(selectedToppings.length){ //Marks the next edit topping to the pizza
        setToppingToEdit(selectedToppings[selectedToppings.length-1]);
    }
  }, [selectedToppings, toppingToEdit])




  const getToppingCost = (topping, pizzaToppingPriceDivide) => {
    let price = 0;
    if(toppingsFree){ //calc topping quarters price (for pack item)
        const quartersFree = toppingsFree * 4;
        let quartersCount = 0; // number of toppings quarters

        selectedToppings.forEach(t => {
          if(pizzaToppingPriceDivide == 1) {
            t.quarterList.forEach(q => {
              if(t.id === topping.id){
                price += (quartersCount < quartersFree  && q)
                            ?
                                0
                            :
                                q
                                ?
                                    t.price/4
                                : 
                                    0;
              }
              quartersCount += q ? 1 : 0;
            })
          } else {
            if (t.quarterList.some(quarter => quarter)) {
              if(t.id === topping.id){
                price += (quartersCount < quartersFree)
                            ?
                                0
                            :
                                t.price;
              }
              quartersCount += 4;
            }
          }
        })
      }
      else{
        if(pizzaToppingPriceDivide == 1) {
          topping.quarterList.forEach(inQuarter => {
            if(inQuarter){
              price += (topping.price / 4)
            }
          })
        } else {
          price += topping.price;
        }
      }
    return (
      price ?
        (<NumberFormat value={price} prefix={currencyName} decimalScale={2} fixedDecimalScale={true} displayType={"text"} />) : (t('free'))
    );
  }

  const getToppingPosImageName = (topping) => {
    const topLeft = topping.quarterList[0] ? 1 : 0;
    const topRight = topping.quarterList[1] ? 1 : 0;
    const bottomLeft = topping.quarterList[2] ? 1 : 0;
    const bottomRight = topping.quarterList[3] ? 1 : 0;

    const ending = pizzaSlices == 2 ? '_2' : '';

    return `./t_${topLeft}${topRight}${bottomLeft}${bottomRight}${ending}.png`;
  }

  const getToppingPosSName = (topping) => {
    const topLeft = topping.quarterList[0] ? 1 : 0;
    const topRight = topping.quarterList[1] ? 1 : 0;
    const bottomLeft = topping.quarterList[2] ? 1 : 0;
    const bottomRight = topping.quarterList[3] ? 1 : 0;

    const ending = pizzaSlices == 2 ? '_2' : '';

    return `./t_${topLeft}${topRight}${bottomLeft}${bottomRight}${ending}.png`;
  }

  const displayPizzaToppingCost = (shopTopping, pizzaToppingPriceDivide) => {
    let price = 0;
    let quartersCount = 0; // number of toppings quarters
    const quartersFree = toppingsFree * 4;

    if(toppingsFree){
      selectedToppings.forEach(t => {
        if(pizzaToppingPriceDivide == 1) {
          t.quarterList.forEach(q => {
            if(t.id === shopTopping.id){
              price += (quartersCount < quartersFree  && q)
                          ?
                              0
                          :
                              q
                              ?
                                  t.price/4
                              : 
                                  0;
            }
            quartersCount += q ? 1 : 0;
          })
        } else {
          if (t.quarterList.some(quarter => quarter)) {
            if(t.id === shopTopping.id){
              price += (quartersCount < quartersFree)
                          ?
                              0
                          :
                              t.price;
            }
            quartersCount += 4;
          }
        }
      })
    }

    const isToppingOnPizza = selectedToppings.some(t => t.id === shopTopping.id && t.quarterList.some(q => q))

    return(
      !shopTopping.price || (toppingsFree && (!price && ((isToppingOnPizza) || quartersCount < quartersFree)))
      ?
        t('free')
      :
        <NumberFormat
          value={shopTopping.price}
          prefix={currencyName}
          decimalScale={2}
          fixedDecimalScale={true}
          displayType={"text"}
        />     
    )
  }

  const renderToppingPosImage = (topping) => {
    const topLeft = topping.quarterList[0];
    const topRight = topping.quarterList[1];
    const bottomLeft = topping.quarterList[2];
    const bottomRight = topping.quarterList[3];

    return pizzaSlices == 2 ? 
      <div className={style.PizzaTops2}>
        <div className={!topLeft ? style.TopLeftEmpty : style.TopLeftFull}></div>
        <div className={!topRight ? style.TopRightEmpty : style.TopRightFull}></div>
      </div>
    :
      <div className={style.PizzaTops4}>
        <div className={!topLeft ? style.TopLeftEmpty : style.TopLeftFull}></div>
        <div className={!topRight ? style.TopRightEmpty : style.TopRightFull}></div>
        <div className={!bottomLeft ? style.BottomLeftEmpty : style.BottomLeftFull}></div>
        <div className={!bottomRight ? style.BottomRightEmpty : style.BottomRightFull}></div>
      </div>
  }

  return (
    <div className={style.Container} >
      
      <div className={style.ToppingsDescription}>{toppingsDescription}<br/>{pizzaSlices == 2 ? t("You can select halves by clicking on the picture") : t("You can select quarters by clicking on the picture")}</div>

      {/* {
        hasLimitedError &&
          <div className={style.MaxToppingsError}>{'** שים לב! כמות תוספות מוגבלת **'}</div>
      } */}
      <div className={style.PizzaContainer}  >

        <div className={style.RightSideTabs}>
          <div className={style.SelectedProductOption}>
            <div className={style.NameProductOption}>{selectedProductOption.name}</div>
            {
              (hasProductOptions && !withoutPrices)
              ?
                <div className={style.PriceProductOption}>
                  {(selectedProductOption.price && optionsPaid !== 0) ?
                    (<NumberFormat 
                      value={selectedProductOption.price}
                      prefix={currencyName}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      displayType={"text"}
                      />
                    ) : (t('free'))}
                </div>
              :
                ''
            }
          </div>
          <div className={style.SelectedToppings}>
              {
                  selectedToppings.map(topping =>
                  {
                    if(topping.quarterList.some(q => q === true))
                    {
                      return(
                        <div className={(toppingToEdit && toppingToEdit.id == topping.id) ? style.ToppingToEdit : style.ToppingToEditOp}
                          title={topping.id}
                          key={topping.id}
                          onClick={() => setToppingToEdit(topping)}>
                            <div className={style.ToppingNameAndPrice}>
                              <div className={style.ToppingName}><span className={style.ToppingXRemover} onClick={() => removeTopping(topping)}>✕</span>{topping.name}</div>
                              <div className={style.ToppingPrice}>{!withoutPrices && getToppingCost(topping, pizzaToppingPriceDivide)}</div>
                            </div>
                            <div className={style.ToppingImgPositions}>
                              {renderToppingPosImage(topping)}
                              {/*<LazyLoadImage effect='blur' src={requestToppingPosImage(getToppingPosImageName(topping)).default} width="68%" />*/}
                              {/* <LazyLoadImage effect='blur' src={t_0000} width='68%' /> */}
                            </div>
                        </div>
                      )
                    }
                    return null;
                  })
                  
              }
          </div>
        </div>
        <div className={style.PizzaIcon} ref={scrollUpRef}>
            {/* <LazyLoadImage effect='blur' src={requestToppingImage(`./pizza.png`).default} width="230px" /> */}
            <LazyLoadImage effect='blur' src={pizza_tmp} width="230"/>

            <div className={style.PizzaCenter} onClick={() => toggleToppingOnAllQuarters(toppingToEdit)}/>

            { ['TopLeftQuarter','TopRightQuarter','BottomLeftQuarter','BottomRightQuarter']
                .map((quarterPosition, _idx)=>
                  <div className={style[quarterPosition]} key={_idx} onClick={() => toggleToppingOnQuarter(toppingToEdit, _idx)}>
                    {
                      selectedToppings.map(({ quarterList, codeName, id }) => !quarterList[_idx] 
                        || <LazyLoadImage effect='black-and-white' key={`${id}-Q${_idx}`} src={ requireOr(`./${codeName}.png`, default_topping) } />
                      )
                    }
                  </div>
                ) 
            }

            {pizzaSlices == 2 ? <>
              <div className={style.LeftHalf} onClick={() => toggleToppingOnQuarter(toppingToEdit, 0)}></div>
              <div className={style.RightHalf} onClick={() => toggleToppingOnQuarter(toppingToEdit, 1)}></div>
            </> : ''}
        </div>
        <div className={style.ChoiceIcon}>
            {selectedToppings.length ? <LazyLoadImage effect='blur' src={requestToppingImage('./choice'+(pizzaSlices == 2 ? '2' : '')+'.png').default} width="240px"/> : ""}
        </div>
      </div>

      <div className={style.ItemToppingList}>
        { hasError && <MaxToppingsMsg closeMaxToppingMsg={closeMaxToppingMsg}
                                      limitedToppingsNumber={(toppingsAddPaid===0 && maxToppings > toppingsFree)
                                                          || maxToppings === null || maxToppings === 0 ? toppingsFree : maxToppings}/> }
        {
          shopToppings.map((st, idx) => {
            return (
              <div key={idx} className={style.ItemTopping}>
                <div className={style.WrapBtnAndName}>
                  <input
                    id={"top_p_"+idx}
                    type="checkbox"
                    value={st.id}
                    name="selectedToppings"
                    checked={selectedToppings.some(topping => topping.id == st.id)}
                    onChange={(e)=> handleChange(e)}
                  />
                  <label htmlFor={"top_p_"+idx} className={style.ItemToppingName}>{st.topping.name}</label>
                </div>
                <span className={style.ItemToppingPrice}>
                  {!withoutPrices && displayPizzaToppingCost(st, pizzaToppingPriceDivide)}
                </span>
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

export default SubForm3;
