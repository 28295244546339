import React from "react";
//import { toJS } from "mobx";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import style from "./style.module.css";
import CartItem from "./CartItem";
import NumberFormat from "react-number-format";
import ItemUpdateForm from "./ItemUpdateForm/index";
import PackItemUpdateForm from "./PackItemUpdateForm";
import EditCartPackForm from "./EditCartPackForm";
import PaymentButtons from "./PaymentButtons";
import { BsBasket3Fill } from "react-icons/bs";
import { IoCartOutline } from "react-icons/io5";
import MissingProductsModal from "./MissingProductsModal";
import OrderTypeSwitch from "./../../Payment/ShippingOptionForm/OrderTypeSwitch";
import { Elements, PaymentMethodMessagingElement } from "@stripe/react-stripe-js";
//import { loadStripe } from "@stripe/stripe-js";
//import ProductsAssociatedModal from "../../ProductsAssociatedModal";

@inject((store) => {
  const { myShopApiStore, ifaceMngr } = store;
  return { myShopApiStore, ifaceMngr };
})

@observer
class Basket extends React.Component {

  constructor(props) {
    super(props)
    this.scrollUpRef = React.createRef();

    this.state = {
      isMobile: window.innerWidth <= 850,
      productsAssociated: null,
      productsAssociatedModalIsOpen: false,
      isBasketOpened: true, 
      isMissingProductsModalOpen: false,
      stripePromise: null,
    }
  }

  updateIsMobile = () => {
    this.setState({ isMobile: window.innerWidth <= 850 });
  }

  componentDidMount = () => {
    this.scrollUpRef.current && !this.state.isMobile && this.scrollUpRef.current.scrollIntoView({behavior: 'smooth'})
    
    //=============== stripe installments ================
    // this.state.stripePromise 
    // || this.props.myShopApiStore.shopData.shop.paymentSystem !== 'stripe'
    // || this.setState({stripePromise: loadStripe(this.props.myShopApiStore.shopData.shop.paymentApiKey)});

    window.addEventListener('resize', this.updateIsMobile); 
  }

  componentDidUpdate() {
    this.scrollUpRef.current && this.scrollUpRef.current.scrollIntoView({behavior: 'smooth'});  
  }

  

  componentWillUnmount() {
    // console.log('Basket > componentWillUnmlount')
    window.removeEventListener('resize', this.updateIsMobile);

    this.props.basketIsOpen && this.props.closeBasket(); //mobile - goes back to menu (and not to basket)!
    
  }
  
  handleCheckboxChange = (event, id) => {
    const { thisCart, calcTotalCartPrice } = this.props.myShopApiStore;
    
    thisCart.cartItemList.forEach(cartItem => {
      if(cartItem.id === id){
        cartItem.isReadyToOrder = event.target.checked;
      }
    })

    calcTotalCartPrice();
  }

  changeOrderType = (toType) => {
        const { setOrderData, resetOrderData, shop, orderData, getItemList} = this.props.myShopApiStore;
        // this.setState({selectedTimeIdx: ''}); 
        if (toType === orderData.orderType) return;
        // resetOrderData(); 
        //setOrderData('isDelivery', toType === 'delivery'); 
        setOrderData('orderType', toType);    
  }

  renderStepsBar = () => {
    const { maxStepNum, setStepNum, cartItemUpdating } = this.props.myShopApiStore;
    return(
      <div>
        <div className={style.StepsBar}>
          {cartItemUpdating.steps.map(({stepNum}) =>
            <React.Fragment key={stepNum}>
                {stepNum !== 1 && <>&nbsp;- -&nbsp;</>}
                <div className={style.StepBar}
                
                    style={{
                            color: 
                                stepNum <= maxStepNum && "#5c5c5c",
                            border:
                                stepNum === this.props.myShopApiStore.stepNum ? "1px solid #5c5c5c" :"1px solid #dcdcdc",
                            cursor:
                                stepNum <= maxStepNum ? "pointer" : "default",
                          }}
                    onClick={()=>{if(stepNum <= maxStepNum) setStepNum(stepNum)}}>
                    {stepNum}
                </div>
            </React.Fragment>
          )}
        </div>
      </div>
    )
  }

  openProductsAssociatedModal = () => {
    this.setState({productsAssociatedModalIsOpen: true});

    if(!this.props.basketIsOpen) {
      this.props.fastToggleBasket();
    }
  }

  closeProductsAssociatedModal = () => {
    this.setState({productsAssociatedModalIsOpen: false});

    if(!this.state.isBasketOpened) {
      this.props.fastToggleBasket();
    }
  }

  checkForProductsAssociated = async (item, isAdded = false) => {
    const { addToCart, isPackItemUpdating,
      isCartItemUpdating } = this.props.myShopApiStore;
    // const item = itemAssociated ? itemAssociated : this.props.item;

    const unitTypes = item.unitTypes ? item.unitTypes : null;
    let isDuringOrder = isPackItemUpdating || isCartItemUpdating;

    if(!isDuringOrder || !isAdded){
      addToCart(item, unitTypes?.length ? unitTypes[0] : null)
      return;
    }
  }

  render() {
    const { cartPrice, cartPriceBeforeDiscount,
            isCartItemUpdating, isEditCartPackForm, isPackItemUpdating, cartItemUpdating, 
            shop, shopData,  thisCart, orderData, orderTypesOvertime } = this.props.myShopApiStore;
    const { currencyName, currencyCode } = shopData.country;
    const { t } = this.props;
    const { isRTL } = this.props.ifaceMngr;
    let itemsInCart = 0;
    thisCart?.cartItemList?.forEach(cartItem => {cartItem.isReadyToOrder && itemsInCart++})
    const isCartPackItem = cartItemUpdating && (cartItemUpdating.item.product.levels ? cartItemUpdating.item.product.levels.length : false);
    return (
      <div className={style.Container} style={isRTL ? {marginLeft: '15px'}: {marginRight: '15px'}}>
        <MissingProductsModal closeModal={() => this.setState({isMissingProductsModalOpen: false})} modalIsOpen={this.state.isMissingProductsModalOpen}/>
        <div className={style.TopDiv} /*style={{height: isCartItemUpdating ? '100%' : '100%'}}*/>
          {
            !this.state.isMobile && 
              <div className={style.Summary}>
                <div className={style.CartWrapper}>
                  <span
                    className={style.ItemsInCart}
                    style={{ left: isRTL ? '-42%': '42%' }}
                  >
                    {itemsInCart}
                  </span>
                  <IoCartOutline className={style.CartIcon} style={{marginLeft: isRTL ? '10px': '-10px'}} />
                </div>
                {
                  !shop.isBusiness && <>
                    <div className={style.CartSum}>
                      { cartPrice > 0 && cartPriceBeforeDiscount > 0 && cartPrice !== cartPriceBeforeDiscount && 
                          <NumberFormat
                            value={cartPriceBeforeDiscount}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            displayType={"text"}
                            style={{textDecoration: 'line-through', fontSize: '14px', color: 'black', margin: '4px'}}
                          />

                      }
                      <div className={style.CartSumVal} >
                        <span className={style.CartSumSh}>{currencyName}</span>
                        <NumberFormat
                          value={cartPrice}
                          // prefix={currencyName}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          displayType={"text"}
                        /> 
                      </div>
                    </div>
                    <div className={style.CartText} style={{ marginLeft: '7px' }}>
                      { t('total') }
                      <br />
                      { t('forPayment') }
                    </div></>
                }
              </div>
          }
          { shop?.isPriceOrderType
            && !this.state.isMobile
            && !isPackItemUpdating 
            && !isCartItemUpdating?
            <OrderTypeSwitch  changeOrderType={this.changeOrderType} 
                              orderType={orderData?.orderType}
                              shop={shop}   
                              thisCart={thisCart}
                              orderTypesOvertime={orderTypesOvertime}
          />:null }
          {
            !!this.state.stripePromise && !!cartPrice && 
            <div style={{ margin: '10px auto 0 auto', display: 'inline-block', whiteSpace: 'nowrap', minWidth: '300px' }}>
              <Elements stripe={this.state.stripePromise} options={{ locale: this.props.ifaceMngr.i18nextLng }} >
                <PaymentMethodMessagingElement 
                  style={{ display: 'inline-block'  }}
                  options={{
                      amount: Math.ceil(cartPrice * 100),
                      currency: currencyCode,
                      paymentMethodTypes: ['klarna', 'afterpay_clearpay', 'affirm'],
                      countryCode: shopData.country.name.substring(0,2).toUpperCase(),
                    }}/>
              </Elements>
            </div>
          }
          { (!isPackItemUpdating && !!isCartPackItem) && this.renderStepsBar() }
          {isCartItemUpdating 
          ? (
            isCartPackItem ? (isEditCartPackForm ? <EditCartPackForm /> : <PackItemUpdateForm checkForProductsAssociated={this.checkForProductsAssociated}/>) : <ItemUpdateForm checkForProductsAssociated={this.checkForProductsAssociated}/>
          ) 
          : (
            <div className={style.CartItems}>
            {
              (thisCart?.cartItemList.length) 
              ? (
                  thisCart.cartItemList.map((cartItem, idx) => {
                    return (
                      <div key={`${cartItem.item.id}_${idx}`} ref={this.scrollUpRef}>
                        <CartItem cartItem={cartItem} index={idx} handleCheckboxChange={this.handleCheckboxChange} />
                      </div>
                    )
                  })
                )
              : (
                <div className={style.CartItemsEmpty} style={{zIndex: 0}} onClick={this.state.isMobile ? this.props.closeBasket : null}>
                  <span>{ t('Your basket is empty') }</span>
                  <span>{ t('Start adding products to your basket short') }</span>
                  <BsBasket3Fill />
                </div>
              )}
            </div>
          )}
          {/*<div className={style.CartButtons}>buttons</div>*/}
            <PaymentButtons 
              hasProducts={thisCart?.cartItemList.some(cartItem => cartItem.isReadyToOrder) && !isCartItemUpdating} 
              openMissingProductsModal={() => this.setState({isMissingProductsModalOpen: true })}/>
        </div>
        
      </div>
      );
  }
}
export default withTranslation()(Basket)