import React, { useEffect, useState, useRef } from 'react';
import { observer, inject } from 'mobx-react';
import { usePhoneInput, defaultCountries, parseCountry, buildCountryData } from 'react-international-phone';
import style from './style.module.css';

@inject("myShopApiStore")
@observer
class RegInput extends React.Component {

    render() {
        const{ myShopApiStore, ...props} = this.props;
        return (
            props.type === 'tel' && !myShopApiStore.shopData.country.default
                ? <Phone  
                    userParams={ myShopApiStore.userParams } 
                    shopCountry={ myShopApiStore.shopData.country }
                    { ...props }
                />
                : <input  { ...props } style={{...(props.type === 'tel' ? {textAlign: document.body.dir === 'rtl' ? 'right' : 'left'}:{})}}/>
        )
    }
}

function useOutsideClick(ref, callback) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [ref, callback]);
}

const Phone = ({ field, className, onChange, userParams, shopCountry, placeholder, value, ...props })  => {
    const { phonePrefixes } = shopCountry;

    const countries = defaultCountries
        .filter(country => {
            const { iso2, dialCode } = parseCountry(country)    
            return phonePrefixes?.includes('+' + dialCode) && ['il', 'us'].includes(iso2);
        })
        .map(country => {
            const pc = parseCountry(country) 
            if (pc.dialCode === "972") {
                pc.format = {
                    'default': '.. ... ....',
                    '/^0/': '... ... ....'
                };
            }
            return buildCountryData(pc);
        });
    
    const {inputValue, phone, country, setCountry, handlePhoneValueChange, inputRef} = usePhoneInput({ 
        value,
        defaultCountry: userParams.country?.alpha2Code?.toLowerCase() ?? shopCountry.alpha2Code.toLowerCase(),
        forceDialCode: true,
        countries, 
        onChange: (  phone, inputValue, country) => {
             onChange({target: {name: 'phone', value: phone?.phone  }}); 
        } 
    });
    const [isRTL, setIsRTL] = useState(false);
    const [isFlagVisible, setIsFlagVisible] = useState(true);
    const [isCountrySelectorVisible, setIsCountrySelectorVisible] = useState(false);
    

    const ref = useRef(null);
    useOutsideClick(ref, () => setIsCountrySelectorVisible(false));
    useEffect(() => {
        const dir = document.body.dir; // שמירה על ערך כיוון הכתיבה במשתנה
        setIsRTL(dir === 'rtl');
    }, []);

    // useEffect(()=> {
    //     setIsFlagVisible(true);
    //     //setIsFlagVisible(!['972','970'].includes(country.dialCode));
    // }, [setIsFlagVisible, country])

    // useEffect(()=> {     
    //     if (country && inputValue?.length && (
    //         !phonePrefixes.includes('+' + country.dialCode) 
    //         || !inputValue.startsWith('+' + country.dialCode) 
    //         )) {
    //         setCountry(shopCountry?.alpha2Code.toLowerCase() ?? 'il');    
    //     }
        
    // }, [country, setCountry, shopCountry, inputValue, phonePrefixes])

    return (
        <div className={style.Container} > 
            {
            isCountrySelectorVisible 
                ? <select name="country" className={style.FlagPlaceholder} value={country.iso2} ref={ref}
                    onChange={(e) => {
                        setCountry(e.target.value); 
                        setIsCountrySelectorVisible(!isCountrySelectorVisible)
                    }} style={{border: 'none'}}>
                        {countries.map((country, i) => {
                            const { iso2, name, dialCode } = parseCountry(country)
                            return <option key={i} value={iso2}>{name} +{dialCode}</option>}
                        )}
                </select> 
                : <div className={style.FlagPlaceholder} style={{cursor: 'pointer'}}
                    onClick={() => setIsCountrySelectorVisible(!isCountrySelectorVisible)}>
                        {country.iso2.toLocaleUpperCase()}
                </div>
            }
            <input 
                type="tel"
                className = { className }
                style={{ ...(isFlagVisible ? {paddingLeft: '50px'} : {}), textAlign: isRTL ? 'right' : 'left', ...props.style}}
                value={inputValue} 
                name="phone"
                onChange={(e)=> {                   
                    if (e.target.value.length < 7 && !e.target.value.startsWith('+' + country.dialCode)) 
                        '+'.concat(country.dialCode).split('').forEach((d, i) => {
                            if (e.target.value[i] !== d) {
                                e.target.value = '+' + country.dialCode + e.target.value[i];
                            }
                        });
                                         
                    handlePhoneValueChange(e);}
                }  
                placeholder={placeholder}
                ref={inputRef}
                // dir="ltr"
            />
        </div>
    )
}

export default RegInput